import { HtmlSanitizer } from '@packages/sanitizer'
import React, { useEffect, useRef } from 'react'

import classMerge from '../utils/classMerge'

import './SanitizedHtml.css'

interface SanitizedHtmlProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  dirtyHtml?: string
  tagsStyle?: Record<string, string>
}

const SanitizedHtml = ({ dirtyHtml = '', className, tagsStyle = {}, ...props }: SanitizedHtmlProps) => {
  const htmlContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    Object.keys(tagsStyle).forEach(tag => {
      htmlContainerRef.current?.querySelectorAll<HTMLElement>(tag).forEach(element => {
        element.style.cssText = tagsStyle[tag]
      })
    })
  }, [dirtyHtml, tagsStyle])

  useEffect(() => {
    htmlContainerRef.current?.querySelectorAll<HTMLLinkElement>('a').forEach(element => {
      element.onclick = e => e.stopPropagation()
    })
  }, [dirtyHtml])

  return (
    <div
      ref={htmlContainerRef}
      dangerouslySetInnerHTML={{ __html: HtmlSanitizer.sanitize(dirtyHtml) }}
      className={classMerge('sanitized-html', className, {
        'sanitized-html__html': HtmlSanitizer.validateType(dirtyHtml),
      })}
      {...props}
    />
  )
}

export { SanitizedHtml }
